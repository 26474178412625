.App {
  text-align: center;
}

.App-header {
  background-color: #8C1515;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 15vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  border-bottom: 3px solid #2E2D29;
}

h3 {
  margin: 0;
  /* color: white; */
}

h2 {
  margin: 0;
  color: white;
}

h1 {
  margin-top: calc(15vh + 10px);
  color: #8C1515;
}

footer {
  background-color: '#53565A';
}